<template>
 <Head>
      <title>{{title}}</title>
      <meta name="description" :content="description" />
      <meta name="keywords" :content="keywords" />
    </Head>


  <div class="home-container">

    <SplashContainer :title="splashTitle" :subtitle="splashSubtitle" :splashImageUrl="splashImage">

      <button 
      
      role="button" 
      class="mt-4 btn orderopia-btn btn-primary"
       data-orderopia-area="order" 
       data-orderopia-business="orderopia-fish-bar" 
       aria-label="order"
        style="font-size: 1.1rem;">
        <span data-v-0afb3cd8="">Order Online</span>
        <img data-v-0afb3cd8="" alt="arrow right" class="ms-2" src="data:image/webp;base64,UklGRvIAAABXRUJQVlA4TOYAAAAvP8APEDU4biRJkfy3Opbh8n48EY4qACCdbwXQ3MG47S2AHcG2zSwLpJNti4HbRorSLBwzvOH/MguaRxC/RcLAWDmFxMR/eCmVEC8ML8VSEfdVhRCwqOeidylUzGfxX06EbfRTyh3K6US/VdVQiNjK0Ei3s1ErFCiyUae8gbfqAkOFNuqBQ1lcN0IOLgaKlp7ugRkUpPrbiTDgFgZhNy/3R8bTwcp7owr3FnP/LHZb7HI4pF2zgX6WekX5fZs/N/Vzm7838vdW/t7M39v5dyP/buXfzfy7nf835P8t+X/TE/y3vaLyBw==" style="height: 20px;"></button>
    </SplashContainer>


    <!-- Our Story -->
    <div class="d-flex flex-column mt-4 mb-5 align-items-center mx-auto" style="max-width:800px;">
      <h4 class="text-primary">Discover</h4>
      <h2>Our Story</h2>
      <div class="mt-2 header-separator"></div>

      <div class="d-flex mx-4 mx-md-0 align-items-center mt-4 our-story">

        <img alt="our story" class="our-story-img mb-3 mb-md-0 col me-0 me-md-4" src="../assets/img/our-story.webp">

        <div class="col">
          <div class="d-flex our-story-title-container">
            <div class="d-none d-md-block me-2 vertical-separator"></div>
            <h3 class="our-story-title">Welcome to Orderopia Fish Bar</h3>
          </div>
          <p class="our-story-content">We pride on using the
            best ingredients and quality products only, ensuring 100% cod in our fish.<br>Orderopia Fish Bar is renowned
            throughout for its divine style and presentation of traditional Fish cuisine, this is
            achieved by paying special attention to every fine detail and only using the very finest ingredients.</p>
        </div>

      </div>
    </div>


    <!-- Online Ordering -->
    <div class="online-ordering">
  <RuggedSeparator :isTop="true"/>



   <!-- <div class="online-ordering-inner-container">
    <h4 class="text-white">Check out</h4>
    <h2 class="text-white">Online Ordering</h2>
    <div class="mt-2 header-separator"></div>


    <div class="d-flex mt-4 online-ordering-inner-container-content" style="width:100%;">
      <img alt="app example" class="col-4 online-ordering-img"  src="../assets/img/app-example.webp">


      <div class="col-md-8 col-12">
        <p class="app-title title-lg text-white">Start your order!</p>

         <button role="button" class="btn orderopia-btn btn-white" data-orderopia-area="order"
        data-orderopia-business="orderopia-fish-bar" aria-label="order">
        <span>Order Online</span>
      <img alt="arrow right" class="ms-2" style="height:20px;" src="../assets/img/arrow-right-dark.webp">
      </button> 

        <p class="my-4 text-white">Or download our bespoke app for an enhanced ordering experience!</p>
        <div class="app-link-container align-self-start d-flex align-items-center">
          <a target="_blank" href="https://apps.apple.com/gb/app/orderopia-fish-bar/id6478897527" class="btn btn-secondary me-2">
            <img alt="app store" class="me-2" style="height:20px;" src="../assets/img/app-store.webp">
            <span>Apple Store</span>
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.orderopia.orderopiafishbar" class="btn btn-secondary">
            <img alt="google play" class="me-2" style="height:20px;" src="../assets/img/google-play.webp">
            <span>Play Store</span>
          </a> 

        </div>
      </div>


    </div>
  
  </div>   -->

  <RuggedSeparator :isTop="false"/>

</div>

    <!-- Menu Item Images-->
    <div class="menu-item-images">
      <img alt="menu item" src="../assets/img/menu-item-1.webp">
      <img alt="menu item" src="../assets/img/menu-item-2.webp">
      <img alt="menu item" src="../assets/img/menu-item-3.webp">
      <img alt="menu item" src="../assets/img/menu-item-4.webp">
      <img alt="menu item" src="../assets/img/menu-item-5.webp">
      <img alt="menu item" src="../assets/img/menu-item-6.webp">
      <img alt="menu item" src="../assets/img/menu-item-7.webp">
      <img alt="menu item" src="../assets/img/menu-item-8.webp">

    </div>

  </div>
</template>

<script>
// import RuggedSeparator from '../components/RuggedSeparator.vue'
import SplashContainer from '../components/SplashContainer.vue'

import splash from "../assets/img/splash.webp";
import { Head } from '@vueuse/head';

export default {
  components: {
    // RuggedSeparator,
    SplashContainer,
    Head
  },
  data() {
    return {
      title: "Orderopia Fish Bar | Best Fish & Chips",
      description: "Fresh food from Orderopia Fish Bar. Order now. The food you really want is just one click away. Order fish and chips online.",
      keywords: "orderopiafishbarnottingham,orderopia fish bar nottingham, orderopiafishbar,orderopia fish bar,orderopia fish bar fish and chips, fish and chips nottingham, fish nottingham, chips nottingham, chippy nottingham",
      splashImage: splash,
      splashSubtitle: "Orderopia Fish Bar",
      splashTitle: "Fish & Chips"

    }
  },
  mounted() {
    // eslint-disable-next-line
    initOrderopia();
  }

}

</script>

<style scoped>


.vertical-separator {
  width: 1px;
  height: 100%;
  background-color: black;
}


.our-story-title-container {
  height: 60px;
}



.our-story-title {
  max-width: 240px;
}

.online-ordering {
  position: relative;
  width: 100%;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
  align-items: center;
  padding: 2rem 0 5rem 0;
}

.online-ordering-inner-container {
  max-width: 800px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.online-ordering-inner-container-content {
  flex-direction: row-reverse;
}

.online-ordering-img {
  height: 300px;
  object-fit: contain;
  margin: auto;
}

.menu-item-images {
  justify-content: center;
  overflow-y: hidden;
  margin: 0 auto;
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  display: flex;
}

.menu-item-images img {
  height: 200px;
  display: block;
  width: 200px;
  object-fit: cover;
}

.app-title {
    max-width:350px;
    padding:0 10px;
    line-height:50px;
  }


@media (max-width:768px) {

  .app-title {
    max-width:none;
    width:100%;
  }

  .app-link-container {
  display:flex;
  align-items: center;
  justify-content: center;
}
  .our-story {
    flex-direction: column;
  }



  .our-story-img {
    width: 100%;
  }

  .our-story-title-container {
    height: auto;
  }

  .our-story-title {
    max-width: 1000px;
  }


  .online-ordering-inner-container-content {
    flex-direction: column;
    text-align: center;
    align-items: center;

  }

  .online-ordering-inner-container-content .btn-white {
    margin: 0 auto;
  }

  .menu-item-images {
    flex-wrap: wrap;
    height: 400px;
  }





}
</style>
